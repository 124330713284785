import { Box, Grid } from "@mui/material";
import VerifyEmailStep, { VerifyEmailProps } from "../../components/Onboarding/VerifyEmailStep/VerifyEmailStep";
import colors from "../../colors";

export type SignUpProps = VerifyEmailProps;


const ChangeEmail = (props: SignUpProps) => (
  <Box display="flex" flexDirection="column" alignItems="center" width="100%" height="100%">
    <Box sx={{ backgroundColor: colors.greyBackdrop, height: '100%', width: '100%' }} overflow='auto'>
      <Box paddingLeft={4} paddingRight={4} paddingTop={10} width="100%" paddingBottom={4}>
        <Grid container>
          <Grid item md={1} xs={12} width={{ xs: '100%' }} />
          <Grid item md={10} xs={12}>
            <VerifyEmailStep
              {...props}
            />
          </Grid>
          <Grid item md={1} xs={12} width={{ xs: '100%' }} />
        </Grid>
      </Box>
    </Box>
  </Box>
);

export default ChangeEmail;
