import { useLocation } from "react-router-dom";
import ChangeEmail from "./ChangeEmail";
import { useDispatch } from "react-redux";
import { ThunkDispatchType, actions } from "../../store";
import { useState } from "react";
import queryString from 'query-string';
import CabinetPage from "../../components/Common/CabinetPage";


const ChangeEmailContainer = () => {
  const [toastMessage, setToastMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [showRequestNewCode, setShowRequestNewCode] = useState(false);
  
  const location = useLocation();
  const params = queryString.parse(location.search);
  const dispatch = useDispatch<ThunkDispatchType>();
  const logout = () => dispatch(actions.auth.logout());


  const onResendCode = async () => {
    const newEmail = params["newEmail"] as string;
    const res = await dispatch(actions.auth.startAmplifyChangeEmail(newEmail));
    if (res.nextStep.updateAttributeStep === 'CONFIRM_ATTRIBUTE_WITH_CODE') {
      setToastMessage('A new code hase been sent, check your inbox.');
    } else {
      // NOTE: This is a valid case for the generic updateAttribute endpoint used to change email, 
      //       but changing email should ALWAYS require a code, so this condition should never get hit.
      setToastMessage('Email has been changed - please log in again');
      const userAttributes = await dispatch(actions.auth.getUserAttributes());
      if (userAttributes.email === params["newEmail"]) {
        await dispatch(actions.auth.changeCabinetEmail(params["newEmail"]));
      }
      logout();
    }
  };

  const handleCodeEntered = async (value: string) => {
    setLoading(true);
    const verifyRes = await dispatch(actions.auth.verifyCognitoUserAttributeChange(
      "email", value
    ));
    if (verifyRes) {
      setErrorMessage([verifyRes.message]);
      setShowRequestNewCode(!!verifyRes.canRequestNewCode);
      setLoading(false);
    } else {
      setErrorMessage([]);
      setShowRequestNewCode(false);
      const userAttributes = await dispatch(actions.auth.getUserAttributes());
      if (userAttributes.email === params["newEmail"]) {
        await dispatch(actions.auth.changeCabinetEmail(params["newEmail"]));
      }
      logout();
    }
  };

  return <CabinetPage pageName="ChangeEmail">
    <ChangeEmail
      email={params["newEmail"] as string}
      onResendCode={onResendCode}
      onCodeEntered={handleCodeEntered}
      toastMessage={toastMessage}
      onClearToastMessage={() => setToastMessage("")}
      loading={loading}
      errorMessage={errorMessage}
      offerNewCode={showRequestNewCode}
    />
  </CabinetPage>;
};

export default ChangeEmailContainer;
